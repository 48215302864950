var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"spinner":"bar-fade-scale","color":"#1976d2","size":"128","is-full-screen":""}}),_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.name == 'xs'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? '50vw'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '40vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '20vw'
        : '20vw'},model:{value:(_vm.shareDialog),callback:function ($$v) {_vm.shareDialog=$$v},expression:"shareDialog"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":"","pt-2":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v("Share Via")])]),_vm._l((_vm.socialMedias),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-left":"","pt-2":""}},[_c('ShareNetwork',{attrs:{"network":item.network,"url":'https://styloop.in' + _vm.$route.fullPath,"title":item.network == 'messenger' || item.network == 'linkedin'
                ? ''
                : _vm.storage.name}},[_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"16px","color":"#000"}},[_c('v-icon',{attrs:{"color":"#000","size":"25"}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title))],1)])],1)})],2)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.photos[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}])},[_c('v-layout',{attrs:{"justify-end":"","fill-height":""}},[_c('v-flex',{attrs:{"align-self-end":"","pr-2":"","text-right":""},on:{"click":function($event){_vm.shareDialog = true}}},[_c('v-btn',{staticStyle:{"opacity":"0.8"},attrs:{"fab":"","width":"30","elevation":"0","height":"30","color":"whitesmoke"}},[_c('v-icon',{staticStyle:{"opacity":"1 !important"},attrs:{"color":"black","size":"20"}},[_vm._v("mdi-share")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
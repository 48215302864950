<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-3 pl-2 pr-5>
      <v-flex align-self-center xs6 sm9 md10 text-left>
        <span class="itemHeading1"> Dynamic Links </span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4>
   
             <v-flex
              xs6
              sm3
              md3
              pl-2
              py-1
              pa-md-5
              v-for="(item, i) in imageList"
              :key="i"
            >
              <v-card
                :outlined="
                  $vuetify.breakpoint.name == 'xs' ||
                  $vuetify.breakpoint.name == 'sm'
                    ? true
                    : false
                "
                tile
                flat
                :style="{
                  'height':
                    $vuetify.breakpoint.name == 'xs'
                      ? 'auto'
                      : $vuetify.breakpoint.name == 'sm'
                      ? 'auto'
                      : $vuetify.breakpoint.name == 'md'
                      ? 'auto'
                      : $vuetify.breakpoint.name == 'lg'
                      ? 'auto'
                      : 'auto',
                }"
                :max-width="widthOfCard"
                :ripple="false"
               
              >
               <!-- @click="
                  $router.push('/Products/' + item.productname + '/' + item._id)
                " -->
                <ImageItem v-bind:product="item" />
              </v-card>
            </v-flex>
      <v-flex xs12 v-if="imageList.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageItem from "./imageItem";
export default {
  components: {
ImageItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      widthOfCard:"250px"
    };
  },

  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/newblog/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.Categories = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.getData) this.getData();
    },
  },
};
</script>
<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-dialog
      v-model="shareDialog"
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '50vw'
          : $vuetify.breakpoint.name == 'md'
          ? '40vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '20vw'
          : '20vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-4>
          <v-flex xs12 pt-2 text-left>
            <span style="font-family: poppinsbold; font-size: 16px"
              >Share Via</span
            >
          </v-flex>
          <v-flex
            xs12
            text-left
            pt-2
            v-for="(item, i) in socialMedias"
            :key="i"
          >
            <ShareNetwork
              :network="item.network"
              :url="'https://styloop.in' + $route.fullPath"
              :title="
                item.network == 'messenger' || item.network == 'linkedin'
                  ? ''
                  : storage.name
              "
            >
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 16px;
                  color: #000;
                "
              >
                <v-icon color="#000" size="25">{{ item.icon }}</v-icon
                >&nbsp;{{ item.title }}</span
              >
            </ShareNetwork>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined elevation="0">
          <v-layout wrap justify-center pa-3>
            <v-flex xs12 sm10>
              <v-img :src="mediaURL + storage.photos[0]">
                <template v-slot:placeholder>
                  <ImageLoader />
                </template>
                <v-layout justify-end fill-height>
                  <v-flex
                    align-self-end
                    pr-2
                    @click="shareDialog = true"
                    text-right
                  >
                    <!-- <v-img
                  width="18px"
                  height="18px"
                  src="../../assets/icons/shareicon2.png"
                >
                </v-img> -->

                    <v-btn
                      style="opacity: 0.8"
                      fab
                      width="30"
                      elevation="0"
                      height="30"
                      color="whitesmoke"
                    >
                      <v-icon
                        style="opacity: 1 !important"
                        color="black"
                        size="20"
                        >mdi-share</v-icon
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      shareDialog: false,
      socialMedias: [
        { network: "whatsapp", title: "Whatsapp", icon: "mdi-whatsapp" },
        // { network: "Messenger", icon: "mdi-facebook-messenger" },
        { network: "facebook", title: " Facebook", icon: "mdi-facebook" },
        { network: "twitter", title: " Twitter", icon: "mdi-twitter" },
        { network: "email", title: "Gmail ", icon: "mdi-email" },
        { network: "linkedin", title: "Linkedin ", icon: "mdi-linkedin" },
        // { network: "telegram",title:"Telegram ",  icon: "mdi-telegram" },
      ],
    };
  },
  methods: {
    editData() {
      this.$emit("stepper", {
        type: "Edit",
        editData: true,
        categoryData: this.storage,
      });
    },
  },
};
</script>